import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import debounce from 'lodash.debounce';
import Loader from '../common/UI/Loader';
import ReactTable from '../common/UI/ReactTable';
import SearchAndButtons from '../common/UI/Search&Buttons';
import CustomToggle from '../common/UI/Toggle';
import services from '../api/api';
import { toastSuccess } from '../common/UI/Toasts';
import CustomModal from './../common/UI/CustomModal';
import { Button } from 'react-bootstrap';
import editIcon from '../assets/images/pencil.svg';
import viewIcon from '../assets/images/eye.svg';
import profileImg from '../assets/images/profile-image.jpg';
import ReactSwitch from 'react-switch';

const AdminDoctorManagement = () => {
  const navigate = useNavigate();
  const columns = [
    {
      Header: 'Full Name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.first_name} {row.original.last_name}
          </span>
        );
      },
    },
    {
      Header: 'Pharmacy Name',
      accessor: 'company_name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.role === 'pharmacist'
              ? row.original.company_name
              : '-'}
          </span>
        );
      },
    },
    {
      Header: 'Email',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <a href={`mailto:${row.original.email}`}>{row.original.email}</a>
        );
      },
    },
    {
      Header: 'Phone',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <a href={`tel:${row.original.phone}`}>{row.original.phone}</a>;
      },
    },
    {
      Header: 'Role',
      accessor: 'role',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return row.original.role == 'doctor' ? (
          <span>clinician</span>
        ) : (
          <span>{row.original.role}</span>
        );
      },
    },
    {
      Header: 'Status',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <ReactSwitch
            uncheckedIcon={false}
            checkedIcon={false}
            checked={row.original.status}
            //  text={row.original.status ? 'Active' : 'InActive'}
            onChange={() =>
              handleStatusChange(row.original._id, row.original.status)
            }
          />
        );
      },
    },
    {
      Header: 'Created Date',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return moment(row.original.createdAt).format('DD-MM-YYYY');
      },
    },
    {
      Header: 'Action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            <span
              className="me-2"
              style={{ cursor: 'pointer' }}
              onClick={() => handleEdit(row.original._id)}
            >
              <img src={editIcon} alt="pencil" width="24" height="24" />
            </span>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setUserInfo(row.original);
                setShowUserInfo(true);
              }}
            >
              <img src={viewIcon} alt="View" width="24" height="24" />
            </span>
            <Button
              onClick={() => {
                resetAuth(row.original._id);
              }}
              className="p-2 m-2 email_send_btn"
            >
              Reset Auth
            </Button>
          </div>
        );
      },
    },
  ];

  const [data, setData] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [showUserInfo, setShowUserInfo] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAdminDoctorPharmacist(currentPageNo);
  }, [currentPageNo, seeAll]);

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const getAdminDoctorPharmacist = (pageNo) => {
    const params = seeAll
      ? { showall: true, role: 'admin,doctor,pharmacist' }
      : {
          perPage: 10,
          page: pageNo,
          role: 'admin,doctor,pharmacist',
        };
    services
      .getUsers(params)
      .then((res) => {
        setData(res?.data?.data?.docs);
        setTotalPage(res?.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const getOnSearch = (value) => {
    const params = {
      search: value,
      role: 'admin,doctor,pharmacist',
    };
    services
      .getUsersOnSearch(params)
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const onSearchDebounce = debounce(getOnSearch, 1000);

  const handleAdminDoctorSearch = (value) => {
    onSearchDebounce(value);
  };

  const handleAddAdminPharmacistDoctor = () => {
    navigate('/register-admin-doctor');
  };

  const handleEdit = (id) => {
    navigate(`/register-admin-doctor?id=${id}`);
  };

  const handleToggleShowUserInfo = () => {
    setShowUserInfo(!showUserInfo);
  };

  const handleStatusChange = (id, status) => {
    const payload = { status: !status };
    services
      .updateUserStatus(id, payload)
      .then((res) => {
        if (res) {
          getAdminDoctorPharmacist(currentPageNo);
          toastSuccess('Status Updated Successfully!!');
        }
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const seeAllHandle = () => {
    setSeeAll((prev) => !prev);
  };

  const resetAuth = (userId) => {
    const payload = { userId: userId };
    services
      .resetAuthenticator(payload)
      .then((res) => {
        toastSuccess('User authenticator reset successfully!!');
      })
      .catch((err) => {
        console.log('error', err);
      });
  }


  return (
    <>
      <CustomModal
        show={showUserInfo}
        module={`${userInfo?.first_name} ${userInfo?.last_name} Info`}
        handleToggle={handleToggleShowUserInfo}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3" style={{ textAlign: 'center' }}>
              <img
                src={userInfo?.photo?.location || profileImg}
                style={{
                  borderRadius: '50%',
                  width: '90px',
                  height: '90px',
                  objectFit: 'cover',
                }}
              />
            </div>
            <div className="col-md-9">
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      <p className="des_2">Full Name: </p>
                    </td>
                    <td>
                      <p className="des_3">{`${userInfo?.first_name} ${userInfo?.last_name}`}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="des_2">Email: </p>
                    </td>
                    <td>
                      <p className="des_3">
                        <a href={`mailto:${userInfo?.email}`}>
                          {userInfo?.email}
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="des_2">Phone: </p>
                    </td>
                    <td>
                      <p className="des_3">
                        <a href={`tel:${userInfo?.phone}`}>{userInfo?.phone}</a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="des_2">Gender: </p>
                    </td>
                    <td>
                      <p className="des_3">{userInfo?.gender}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="des_2">Role: </p>
                    </td>
                    <td>
                      {userInfo?.role == 'doctor' ? (
                        <p className="des_3">clinician</p>
                      ) : (
                        <p className="des_3"></p>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="des_2">Status: </p>
                    </td>
                    <td>
                      <p className="des_3">
                        {userInfo?.status ? 'Active' : 'Inactive'}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="des_2">Created Date: </p>
                    </td>
                    <td>
                      <p className="des_3">
                        {moment(userInfo?.createdAt).format('DD-MM-YYYY')}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </CustomModal>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div className="header_top">
                  <h3>Admin/Clinician/Pharmacist Management</h3>
                </div>
                <div className="middle_main">
                  <SearchAndButtons
                    onSearchChange={handleAdminDoctorSearch}
                    onButtonClick={handleAddAdminPharmacistDoctor}
                    searchPlaceholder={'Search by name...'}
                    buttonName={'Add Admin/Clinician/Pharmacist'}
                    seeAllHandle={seeAllHandle}
                  />
                  {loading ? (
                    <Loader loading={loading} />
                  ) : (
                    <ReactTable
                      title="Admin/Clinician/Pharmacist"
                      tableColumns={columns}
                      tableData={data}
                      seeAll={seeAll}
                      nextHandle={nextHandle}
                      prevHandle={prevHandle}
                      totalPage={totalPage}
                      pageNo={currentPageNo}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDoctorManagement;
