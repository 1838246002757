import React from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom';
import POMRegisterOrdersTable from '../components/POMRegisterOrdersTable';

const POMRegisterOrders = () => {
  const { id } = useParams();
  const [params] = useSearchParams();
  const userName = params.get('name')
  const site = params.get('website')
  const dob = params.get('dob')
  
  return (
    <div>
      <POMRegisterOrdersTable userId={id} userName={userName} site={site} dob={dob}/>
    </div>
  )
}

export default POMRegisterOrders
