import React, { useEffect, useState } from 'react';
import SearchAndButtons from '../../common/UI/Search&Buttons';
import Loader from '../../common/UI/Loader';
import ReactTable from '../../common/UI/ReactTable';
import eyeIcon from '../../assets/images/eye_gray.svg';
import axios from 'axios';
import moment from 'moment';

const POMRegisterOrdersTable = ({userId, userName, site, dob}) => {
  const [show, setShow] = useState('Table');
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [seeAll, setSeeAll] = useState(true);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [ordersList, setOrdersList] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [farmeciUsersData, setFarmeciUsersData] = useState([])
  const [mlpUsersData, setMlpUsersData] = useState([])

  useEffect(()=> {
    fetchOrders();
  }, [])
  
  const columns = [
    {
      Header: 'Name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            {userName}
          </div>
        )
      }
    },
    {
      Header: 'DOB',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            {dob}
          </div>
        )
      }
    },
    {
      Header: 'Pt. Address',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            {`${row?.original?.orderData?.address?.address_line1} ${row?.original?.orderData?.address?.address_line2}, ${row?.original?.orderData?.address?.city}, ${row?.original?.orderData?.address?.country}, ${row?.original?.orderData?.address?.zip_code}`}
          </div>
        )
      }
    },
    {
      Header: 'Order No',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            {row?.original?.orderData?.order_number}
          </div>
        )
      }
    },
    {
      Header: 'dispensed Date',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            {moment(row?.original?.orderData?.status?.find((el) =>
              el?.status.includes('Dispatched by Pharmacist'),
            )?.date).format('DD/MM/YYYY')}
          </div>
        )
      }
    },
    {
      Header: 'Prescriber',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            {`${row?.original?.orderData?.doctor?.first_name} ${row?.original?.orderData?.doctor?.last_name}`}
          </div>
        )
      }
    },
    {
      Header: 'Pharmacy Address',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            45, Newman Street, Soho, W1T 1QE
          </div>
        )
      }
    },
    {
      Header: 'Medication',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            {row?.original?.orderData?.order_items?.product?.name}
          </div>
        )
      }
    },
    {
      Header: 'Variant',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
             {JSON.stringify(row?.original?.orderData?.order_items?.variant?.attributes).substring(1,JSON.stringify(row?.original?.orderData?.order_items?.variant?.attributes).length - 1)}
          </div>
        )
      }
    },
    {
      Header: 'Total',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            £{row?.original?.orderData?.total}
          </div>
        )
      }
    },
  ];

  const fetchOrders = async () => {
    setLoading(true)
    let authToken = localStorage.getItem('authToken');
    let headers =  {'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}` 
                  }
    let url = ``
    if(site == 'farmeci')
    {
      url = 'https://api.farmeci.com/'
    } else if (site == 'mlp') {
      url = 'https://api.mylondonpharmacy.co.uk/'
    }
    else if (site == 'mayfair') {
      url = 'https://api.mayfairclinic.ae/'
    }
    else if (site == 'lovelymeds') {
      url = 'https://api.lovelymeds.com/'
    }
    axios.get(url + `order/ordersByUserId`, {
      headers: headers,
      params: {user_id: userId},
      responseType: 'json',
    })
    .then((response) => {
      setOrdersList(response.data.data)
      setLoading(false)
    })
    .catch((error) => {
      console.log(error)
      setLoading(false)
    }
    );
  }

  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div className="header_top">
                  <h3>Orders List</h3>
                </div>
                <div className="middle_main">
                 
                  {loading ? (
                    <Loader loading={loading} />
                  ) : (
                    <ReactTable
                      title="Blogs"
                      tableColumns={columns}
                      tableData={ordersList}
                      seeAll={seeAll}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </>
  );
};

export default POMRegisterOrdersTable;
