import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import html2pdf from 'html2pdf.js';
import CustomDropdown from '../../../common/UI/Dropdown';
import CustomerDetails from './customerDetails';
import ProductDetailTable from './productDetailTable';
import QtyAlterModal from './qtyAlterModal';
import StatusLogs from './statusLogs';
import arrowDown from '../../../assets/images/arrow-down.png';
import Communications from './communications';
import SendText from './sendText';
import { applyMiddleware } from 'redux';
import api from '../../../api/api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import order, {
  emptyOrderData,
  getOrderById,
  setOrderData as setReducerOrderData,
} from '../../../store/reducers/order';
import { useSelector } from 'react-redux';
import Loader from '../../../common/UI/Loader';
import {
  isUserAdmin,
  isUserDoctor,
  isUserPharmacist,
} from '../../../utils/helpers';
import { toastError, toastSuccess } from '../../../common/UI/Toasts';

import topBanner from '../../../assets/images/top_layer.png';
import emailIcon from '../../../assets/images/icon1.png';
import phoneIcon from '../../../assets/images/icon2.png';
import bottomBanner from '../../../assets/images/bottom.png';

import PastOrders from './pastOrders';
import InternalNotes from './internalNotes';
import {
  deliveryLabel,
  drugLabelHTML,
  prescriptionTemplate,
  COUNTRY_OPTIONS,
  gonnoreahDrugLabelHTML1,
} from '../../../common/constants';
import PhotoSection from './photoSection';
import PharmacistLogs from './pharmacistLogs';
import { cloneDeep } from 'lodash';
import GPContact from './gpContact';
import UpdateOrderDetails from './updateDetails';
import WeightEditModal from './weightEditModal';

const weightOptions = [
  {
    label: '< 100gm',
    value: '99',
  },
  {
    label: 'Between 100-750',
    value: '749',
  },
  {
    label: '> 750',
    value: '850',
  },
];

const postageOptions = [
  {
    label: 'Letter',
    value: 'letter',
  },
  {
    label: 'Large Letter',
    value: 'largeLetter',
  },
  {
    label: 'Parcel',
    value: 'parcel',
  },
];

const ViewOrderPage = () => {
  const { orderData, loading } = useSelector((state) => state.order);
  const { id } = useParams();
  const location = useLocation();
  const userId = orderData?.user?._id;
  const dispatch = useDispatch();
  const [showAlterQtyModal, setShowAlterQtyModal] = useState(false);
  const navigate = useNavigate();
  const [labelOptions, setLabelOptions] = useState([
    {
      label: 'Delivery Label',
      value: 'delivery',
    },
  ]);
  const pharmacistsAndDoctors = useSelector(
    (state) => state.order.pharmacistsAndDoctors,
  );

  const [pastOrders, setPastOrders] = useState([]);
  const [selectedWeightAndPostage, setSelectedWeightAndPostage] = useState();
  const [countryObj, setCountryObj] = useState(null);
  const [previousNotes, setPreviousNotes] = useState(
    orderData?.internalnotes_detail,
  );

  const [editWeightForm, setEditWeightForm] = useState(false);
  const [weightObj, setWeightObj] = useState({});
  useEffect(() => {
    getPastOrders();
  }, [userId, id]);

  useEffect(() => {
    if (
      orderData?.delivery?.weight ||
      orderData?.delivery?.packageFormatIdentifier
    ) {
      setSelectedWeightAndPostage({
        weight: weightOptions.find(
          (el) => el.value === orderData?.delivery?.weight,
        ),
        postage: postageOptions.find(
          (el) => el.value === orderData?.delivery?.packageFormatIdentifier,
        ),
      });
    }
  }, [orderData]);

  const getPastOrders = () => {
    if (userId && id) {
      const payload = {
        user_id: userId,
        order_id: id,
      };
      api
        .getPastOrders(payload)
        .then((res) => {
          if (res?.data?.data?.length > 0) {
            setPastOrders(res.data.data);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  useEffect(() => {
    // printDrugLabel();
    // printDispensingPrescription();
    const weightValue = weightOptions.find(
      (el) => el?.value === orderData?.delivery?.weight,
    );
    const postageValue = postageOptions.find(
      (el) => el?.value === orderData?.delivery?.packageFormatIdentifier,
    );
    setSelectedWeightAndPostage({
      weight: weightValue,
      postage: postageValue,
    });
    return () => {
      setSelectedWeightAndPostage({});
    };
  }, []);

  const printDrugLabel = (data) => {
    const options = {
      margin: 1,
      filename: `Drug Label`,
      html2canvas: { scale: 3 },
      jsPDF: { unit: 'px', format: [227, 152], orientation: 'landscape' },
    };
    if (data.orderItem[0].product.name != 'Gonorrhea Tablets') {
      let drugHTML = ''
      data.orderItem.map((oi) => {
        for (let i = 0; i < oi?.quantity; i++) {
          drugHTML = `${drugHTML} ${drugLabelHTML(oi, data.user)}`;
        }
      })
      console.log('dg', drugHTML);
      html2pdf().set(options).from(drugHTML).save();
    } else {
      const options1 = {
        margin: 1,
        filename: `Gonorrhea Tablets - Drug Label`,
        html2canvas: { scale: 3 },
        jsPDF: { unit: 'px', format: [227, 152], orientation: 'landscape' },
      };
      const drugHTML1 = gonnoreahDrugLabelHTML1(data);
      html2pdf().set(options1).from(drugHTML1).save();
    }
  };

  const printDispensingPrescription = async () => {
    const options = {
      margin: 1,
      filename: 'Prescription.pdf',
      html2canvas: { scale: 3 },
      jsPDF: { unit: 'px', format: 'a4', orientation: 'portrait' },
    };
    let orderDataClone = cloneDeep(orderData);

    // get pharmacist object
    const userObject = pharmacistsAndDoctors.find((el) => {
      return el._id === orderDataClone?.pharmacist_id;
    });
    if (userObject) {
      orderDataClone.pharmacist = userObject;
      // user_name = `${userObject.first_name} ${userObject.last_name} (${userObject.role})`;
    } else {
      // call api to get name
      const res = await api.getUserById(orderDataClone?.pharmacist_id);
      const thisUser = res?.data?.data;
      orderDataClone.pharmacist = thisUser;
      // user_name = `${thisUser.first_name} ${thisUser.last_name} (${thisUser.role})`;
    }
    const dispensingHTML = prescriptionTemplate(orderDataClone);
    html2pdf().set(options).from(dispensingHTML).save();
  };

  const printDeliveryLabelForVIP = () => {
    const options = {
      margin: 1,
      filename: 'DeliveryLabel.pdf',
      html2canvas: { scale: 3 },
      jsPDF: { unit: 'px', format: [350, 150], orientation: 'landscape' },
    };
    const drugHTML = deliveryLabel(orderData);

    html2pdf().set(options).from(drugHTML).save();
  };

  useEffect(() => {
    let label_options = [];
    label_options.push({
      label: `Drug Label`,
      value: `drug-label`,
      labelType: 'drug',
    });
    orderData?.orderItem?.forEach((element) => {
      if (element?.product?.type === 'medication' && element?.quantity != 0) {
        label_options.push({
          label: `Information Label(${element?.product?.name})`,
          value: `information-label-${element?.product?.slug}`,
          labelType: 'information',
          orderItemId: element?._id,
        });
        
      }
    });
    setLabelOptions([
      {
        label: 'Delivery Label',
        value: 'delivery',
      },
      ...label_options,
    ]);
  }, [orderData]);

  const toggleAlterQtyModal = () => {
    setShowAlterQtyModal((prev) => !prev);
  };

  const toggleWeightModal = () => {
    setEditWeightForm((prev) => !prev);
  };

  const changeItemsQuantities = (products) => {
    const payload = {
      order_id: id,
      products: products,
    };
    api
      .changeProductsQuantities(payload)
      .then((res) => {
        toastSuccess('Quantities changed successfully');
        toggleAlterQtyModal();
        dispatch(getOrderById(id));
      })
      .catch((err) => {
        console.log('err:', err);
      });
  };

  const printInformationLabel = (data) => {
    const downloadLink = data?.product?.information_label?.location;
    if (downloadLink) {
      let today = new Date();
      let dateTime = `${today.getFullYear()}_${
        today.getMonth() + 1
      }_${today.getDate()}_${today.getHours()}_${today.getMinutes()}_${today.getSeconds()}`;
      const link = document.createElement('a');
      link.href = downloadLink;
      link.setAttribute('download', `${data.product.name} - Info. Label.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  };
  return (
    <div>
      <>
        <div className="row">
          <div className="col-md-3">
            <ol className="breadcrumb">
              <li>
                <p
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(emptyOrderData());
                    navigate('/orders');
                  }}
                  title="Product Management"
                >
                  All Orders
                </p>
              </li>
              <li>View Order</li>
            </ol>
          </div>
          <div className="col-md-9">
            {
              // if user role is admin then this buttons will be shown
              isUserAdmin() || isUserPharmacist() ? (
                <div className="mb-1" style={{ textAlign: 'right' }}>
                  <div
                    className="d-flex"
                    style={{ justifyContent: 'flex-end' }}
                  >
                    <div className="d-flex">
                      <label className="mt-2 mx-2 form-label">Weight</label>
                      <CustomDropdown
                        isDisabled={
                          orderData?.current_order_status === 'dispatched' ||
                          orderData?.current_order_status === 'rejected'
                        }
                        isClearable={true}
                        className=""
                        options={weightOptions}
                        isSearchable={false}
                        height={'40px'}
                        placeholder={'Select..'}
                        placeholderColor={'#9aa5ad'}
                        border={'1 px solid #9aa5ad'}
                        containerWidth={'250px'}
                        onChange={(value) => {
                          setSelectedWeightAndPostage((prev) => {
                            return {
                              ...prev,
                              weight: value,
                            };
                          });
                        }}
                        value={selectedWeightAndPostage?.weight}
                      />
                      <label className="mt-2 mx-2 form-label">Postage</label>
                      <CustomDropdown
                        isDisabled={
                          orderData?.current_order_status === 'dispatched' ||
                          orderData?.current_order_status === 'rejected'
                        }
                        isClearable={true}
                        className=""
                        options={postageOptions}
                        isSearchable={false}
                        height={'40px'}
                        placeholder={'Select..'}
                        placeholderColor={'#9aa5ad'}
                        border={'1 px solid #9aa5ad'}
                        containerWidth={'220px'}
                        onChange={(value) => {
                          setSelectedWeightAndPostage((prev) => {
                            return {
                              ...prev,
                              postage: value,
                            };
                          });
                        }}
                        value={selectedWeightAndPostage?.postage}
                      />
                    </div>

                    <>
                      {!orderData?.address?.country_code && (
                        <>
                          <label className="mt-2 mx-2 form-label">
                            Country
                          </label>
                          <CustomDropdown
                            isDisabled={
                              orderData?.current_order_status ===
                                'dispatched' ||
                              orderData?.current_order_status === 'rejected'
                            }
                            isClearable={true}
                            className=""
                            options={COUNTRY_OPTIONS}
                            isSearchable={false}
                            height={'40px'}
                            placeholder={'Select..'}
                            placeholderColor={'#9aa5ad'}
                            border={'1 px solid #9aa5ad'}
                            containerWidth={'250px'}
                            onChange={(value) => {
                              setCountryObj(value);
                            }}
                            // value={countryObj?.value}
                          />
                        </>
                      )}
                    </>

                    <div className="d-flex">
                      {isUserAdmin() &&
                      orderData?.current_order_status !== 'dispatched' ? (
                        <Button
                          className="mx-2 p-2"
                          style={{ borderRadius: '0px' }}
                          onClick={toggleAlterQtyModal}
                        >
                          Change Qty
                        </Button>
                      ) : null}

                      <div
                        className="d-flex mx-2"
                        style={{ justifyContent: 'flex-end' }}
                      >
                        <CustomDropdown
                          options={labelOptions}
                          placeholder={'Labels'}
                          controlStyles={{
                            backgroundColor: '#088fff',
                            color: 'white',
                          }}
                          isSearchable={false}
                          menuWidth={'180px'}
                          placeholderStyles={{
                            textAlign: 'center',
                            color: 'white',
                          }}
                          height={'47px'}
                          borderRadius="0px"
                          containerWidth={'180px'}
                          controlShouldRenderValue={false}
                          showIndicator="none"
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                          onChange={(value) => {
                            if (
                              orderData?.current_order_status !== 'dispatched'
                            ) {
                              toastError(
                                'Order not dispatched yet. Cannot print delivery label.',
                              );
                              return;
                            }
                            const thisOrderItem = orderData?.orderItem?.find(
                              (el) => el._id === value.orderItemId,
                            );
                            if (value?.value === 'delivery') {
                              if (
                                orderData?.delivery.service === 'quiver' ||
                                orderData?.delivery.service === 'royal_mail'
                              ) {
                                api
                                  .getDeliveryLabel(orderData?._id)
                                  .then((res) => {
                                    // console.log(res.data.data.deliveryLabel);
                                    const url = window.URL.createObjectURL(
                                      new Blob(
                                        [
                                          new Uint8Array(
                                            res?.data?.data?.deliveryLabel?.data,
                                          ).buffer,
                                        ],
                                        {
                                          type: 'application/pdf',
                                        },
                                      ),
                                    );
                                    if (url) {
                                      let today = new Date();
                                      let dateTime = `${today.getFullYear()}_${
                                        today.getMonth() + 1
                                      }_${today.getDate()}_${today.getHours()}_${today.getMinutes()}_${today.getSeconds()}`;
                                      const link = document.createElement('a');
                                      link.href = url;
                                      link.setAttribute(
                                        'download',
                                        `delivery${dateTime}.pdf`,
                                      );
                                      document.body.appendChild(link);
                                      link.click();
                                      link.parentNode.removeChild(link);
                                    }
                                  });
                              } else if (
                                orderData?.delivery.service === 'vip'
                              ) {
                                printDeliveryLabelForVIP();
                              } else if (
                                orderData?.delivery?.service === 'dhl'
                              ) {
                                const url =
                                  orderData?.delivery_api_response
                                    ?.deliveryLabel;
                                window.open(url, '_blank');
                              }
                              const thisOrderItem = orderData?.orderItem?.find(
                                (el) => el._id === value.orderItemId,
                              );
                            } else if (value?.labelType === 'information') {
                              printInformationLabel(thisOrderItem);
                            } else {
                              printDrugLabel({
                                ...orderData,
                                user: orderData?.user,
                                createdAt: orderData?.createdAt,
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null
            }
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-12 ">
            <CustomerDetails data={orderData} />
          </div>
        </div>
        {isUserAdmin() || isUserDoctor() ? (
          <div className="row mb-3">
            <div className="col-md-12 ">
              <div className="box_main">
                <div className="middle_main">
                  <UpdateOrderDetails orderData={orderData} />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="row mb-3">
          <div className="col-md-12 ">
            <div className="box_main">
              <div className="middle_main">
                <ProductDetailTable
                  printPrescription={printDispensingPrescription}
                  printDrugLabel={printDrugLabel}
                  printInformationLabel={printInformationLabel}
                  printDeliveryLabelForVIP={printDeliveryLabelForVIP}
                  selectedWeightAndPostage={selectedWeightAndPostage}
                  countryObj={countryObj}
                  data={orderData}
                />
              </div>
            </div>
          </div>
        </div>

        {orderData?.subscription?.notes?.length > 0 && (
          <div className="row mb-3">
            <div className="col-md-12">
              <div className="box_main">
                <div className="middle_main">
                  <div
                    className="header_top "
                    style={{ justifyContent: 'space-between' }}
                  >
                    <h3>Subscription Notes</h3>
                  </div>
                  <div
                    className="mb-2  weightStats"
                    style={{ padding: '6px', backgroundColor: '' }}
                  >
                    <table className="internal_notes_table">
                      <th>Sr. No</th>
                      <th>Note</th>
                      {orderData?.subscription?.notes.map((note, ind) => {
                        return (
                          <tr key={ind}>
                            <td>{ind + 1}</td>
                            <td>{note?.note}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row mb-3">
          <div className="col-md-12">
            <div className="box_main">
              <div className="middle_main">
                <StatusLogs data={orderData?.status} />
              </div>
            </div>
          </div>
        </div>

        {(isUserAdmin() || isUserDoctor()) &&
        orderData?.Weight &&
        orderData?.weightHistory ? (
          <div className="row mb-3">
            <div className="col-md-12 ">
              <div className="box_main">
                <div className="middle_main">
                  <div
                    className="header_top "
                    style={{ justifyContent: 'space-between' }}
                  >
                    <h3>Weight Statistics</h3>
                    {/* <button
                      className="btn btn-primary"
                      onClick={() => setEditWeightForm(true)}
                    >
                      Edit
                    </button> */}
                  </div>
                  {/* {editWeightForm ? (
                    <>
                      <WeightEditForm
                        startWeight={orderData.Weight}
                        startDate={orderData?.subscription?.started_date}
                        followupWeight={orderData?.weightHistory}
                      />
                    </>
                  ) : ( */}
                  <div
                    className="mb-2  weightStats"
                    style={{ padding: '6px', backgroundColor: '' }}
                  >
                    <table>
                      <tr>
                        <td>
                          <b>Date: </b>
                          {orderData?.subscription?.started_date?.substring(
                            8,
                            10,
                          )}
                          /
                          {orderData?.subscription?.started_date?.substring(
                            5,
                            7,
                          )}
                          /
                          {orderData?.subscription?.started_date?.substring(
                            0,
                            4,
                          )}
                        </td>
                        <td>
                          <b>Start Weight: </b> {orderData?.Weight}
                        </td>
                        <td>
                          <b>BMI: </b>
                          {(
                            (orderData?.Weight /
                              orderData?.Height /
                              orderData?.Height) *
                            10000
                          ).toFixed(2)}
                        </td>
                        <td></td>
                        <td>
                          {' '}
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              setEditWeightForm(true);
                              setWeightObj({
                                weight: orderData?.Weight,
                                order_items_id: orderData._id,
                                updateHeightWeight: true,
                              });
                            }}
                          >
                            Edit
                          </button>
                        </td>
                      </tr>
                      {orderData?.weightHistory?.map((ele, ind) => {
                        if (ele?.weight != '') {
                          return (
                            <tr key={ind}>
                              <td>
                                <b>Date: </b>
                                {ele.createdAt.substring(8, 10)}/
                                {ele.createdAt.substring(5, 7)}/
                                {ele.createdAt.substring(0, 4)}
                              </td>
                              <td>
                                <b>Weight: </b> {ele?.weight}
                              </td>
                              <td>
                                <b>BMI: </b>
                                {(
                                  (ele?.weight /
                                    orderData?.Height /
                                    orderData?.Height) *
                                  10000
                                ).toFixed(2)}
                              </td>
                              <td>
                                <b>Weight Change: </b>
                                {(
                                  (ele?.weight / orderData?.Weight - 1) *
                                  100
                                ).toFixed(2)}
                                %
                              </td>
                              <td>
                                {' '}
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    setEditWeightForm(true);
                                    setWeightObj(ele);
                                  }}
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </table>
                  </div>
                  {/* )} */}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {isUserAdmin() || isUserDoctor() || isUserPharmacist() ? (
          <div className="row mb-3">
            <div className="col-md-12 ">
              <div className="box_main">
                <div className="middle_main">
                  <Communications orderData={orderData} />
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {isUserAdmin() || isUserDoctor() || isUserPharmacist() ? (
          <div className="row mb-3">
            <div className="col-md-12 ">
              <div className="box_main">
                <div className="middle_main">
                  <SendText orderData={orderData} />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="row mb-3">
          <div className="col-md-12 ">
            <div className="box_main">
              <div className="middle_main">
                <InternalNotes previousNotes={previousNotes} />
              </div>
            </div>
          </div>
        </div>
        {orderData?.current_order_status === 'dispatched' &&
        orderData?.gp_details_provided ? (
          <div className="row mb-3">
            <div className="col-md-12 ">
              <div className="box_main">
                <div className="middle_main">
                  <GPContact />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="row mb-3">
          <div className="col-md-12 ">
            <div className="box_main">
              <div className="middle_main">
                <PastOrders data={pastOrders} />
              </div>
            </div>
          </div>
        </div>
      </>

      {showAlterQtyModal && (
        <QtyAlterModal
          show={showAlterQtyModal}
          orderItems={orderData?.orderItem}
          actualOrderItems={orderData?.actualOrder?.orderItems}
          toggle={toggleAlterQtyModal}
          onChangeAction={changeItemsQuantities}
        />
      )}

      {editWeightForm && (
        <WeightEditModal
          show={editWeightForm}
          toggle={toggleWeightModal}
          weightObj={weightObj}
        />
      )}
    </div>
  );
};

export default ViewOrderPage;
