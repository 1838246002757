import React from 'react'
import POMRegisterListing from '../components/POMRegisterListing'

const POMRegister = () => {
  return (
    <div>
      <POMRegisterListing />
    </div>
  )
}

export default POMRegister
