import _, { cloneDeep, debounce, groupBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import api from '../api/api';
import ProductDetails from '../components/addProducts/productDetails';
import SpecificQuestions from '../components/addProducts/SpecificQuestions';
import RecommendedSupplements from './../components/addProducts/RecommendedSupplements/index';
import { toastError, toastSuccess } from '../common/UI/Toasts';
import SEOModal from '../common/UI/CustomModal/SEOModal';
import * as Yup from 'yup';
import {
  getErrorMessage,
  getFormDataFromObject,
  getLabelValuePair,
  regex,
} from '../utils/helpers';
import {
  PRODUCT_STATUS_OPTIONS,
  VARIANT_ATTRIBUTE_OPTIONS,
  VAT_RATE_OPTIONS,
} from '../common/constants';
import {
  getProductOptions,
  getCategoryOptions,
  reorder,
  getProductPayload,
  excludeCurrentProductFromList,
} from '../utils/productHelper';
import Loader from '../common/UI/Loader';
import SubscriptionDetails from '../components/addProducts/SubscriptionDetails';
import QuestionOptionModal from '../components/GeneralHealthQuestions/questionOptionModal';
import LandingpageDetails from '../components/addProducts/landingpageDetails';
import PreAssessmentForm from '../components/addProducts/preAssessmentForm';

const emptyQuestion = {
  question: '',
  questionType: { label: 'Input', value: 'input' },
  preferredAnswer: null,
  subQuestions: [],
  isAnswerDisabled: true,
  isHTML: false,
};

const AddProduct = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const productType = searchParams.get('type');
  const productId = searchParams.get('id');
  const [healthGoal, setHealthGoal] = useState([]);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [selectedQuestionsIndex, setSelectedQuestionsIndex] = useState(null);

  const initialProductData = {
    name: '',
    showProductName: '',
    description: '',
    brandForRecSupp: { label: 'All', value: 'all' },
    rec_supp_description: '',
    recommended_product: [],
    faq: [],
    tick_points: [],
    status: true,
    type: productType,
    health_goal: null,
    photos: [],
    has_variant: false,
    variantAttributes: [],
    variant_list: {},
    strengths: [
      {
        rrp_price: '',
        trade_price: '',
        margin: '',
        barcode: '',
        stock_level: '',
        sku: '',
        isPrimary: true,
        attributes: null,
        subscription_price: [],
      },
      // {
      //   strength: '',
      //   attributes: [
      //     {
      //       size: '',
      //       rrp_price: '',
      //       trade_price: '',
      //       margin: '',
      //       barcode: '',
      //       stock_level: '',
      //       isPrimary: true,
      //     },
      //   ],
      // },
    ],
    category: [],
    sub_category: [],
    brand: '',
    vat_rate: null,
    sku: '',
    related_keyword: [],
    ingredients: '',
    // Subscription related
    is_subscribable: false,
    follow_up_questions: [],
    subscription_email_template: '',
    subscription_span: [],
    only_subscribable: false,
    deletetable_variant: [],
  };

  const initialMedicineData = {
    name: '',
    emailTemplate: '',
    showProductName: '',
    // about: '',
    category: [],
    brand: '',
    sub_category: [],
    description: '',
    rec_supp_description: '',
    recommended_product: [],
    faq: [],
    tick_points: [],
    status: true,
    type: productType,
    health_goal: null,
    photos: [],
    information_label: null,
    isFridgeItem: false,
    has_variant: false,
    variantAttributes: [],
    variant_list: {},
    strengths: [
      {
        rrp_price: '',
        trade_price: '',
        margin: '',
        barcode: '',
        stock_level: '',
        sku: '',
        isPrimary: true,
        attributes: null,
        subscription_price: [],
      },
      // {
      //   strength: '',
      //   size_variants: [
      //     {
      //       size: '',
      //       rrp_price: '',
      //       trade_price: '',
      //       margin: '',
      //       barcode: '',
      //       stock_level: '',
      //       isPrimary: true,
      //     },
      //   ],
      // },
    ],
    vat_rate: null,
    sku: '',
    related_keyword: '',
    // how_works: '',
    question_answer: [],
    // Subscription related
    is_subscribable: false,
    follow_up_questions: [],
    subscription_email_template: '',
    subscription_span: [],
    only_subscribable: false,
    has_landingpage: false,
    landingpage_url: '',
    landingpage_title: '',
    shoparize_title: '',
    shoparize_brand: '',
    shoparize_product_type: '',
    shoparize_condition: '',
    shoparize_description: '',
    shoparize_price: '',
    shoparize_availability: '',
    shoparize_gtin: '',
    shoparize_gender: '',
    shoparize_category: '',
    shoparize_sales_price: '',
    landingpage_image: [],
    landingpage_description: '',
    landingpage_faq: [],
    landingpage_tick_points: [],
    deletetable_variant: [],
    preRegPhotos: [],
    preRegDescription: '',
    preRegFaq: [],
    preRegName: ''
  };
  const [loading, setLoading] = useState(false);
  const [currStep, setCurrStep] = useState(0);
  const [calcMargin, setCalcMargin] = useState(0);
  const [show, setShow] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [selectData, setSelectedData] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [keywordOptions, setKeywordOptions] = useState([]);
  const [hasVatRateChanged, setHasVatRateChanged] = useState(false);
  const [isPrimarySet, setIsPrimarySet] = useState(true);
  const [showMetaModel, setShowMetaModel] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [metaDataInitialValues, setMetaDataInitialValues] = useState({
    metadata: [
      {
        name: null,
        content: '',
      },
    ],
  });
  const [productData, setProductData] = useState(
    productType === 'product' ? initialProductData : initialMedicineData,
  );
  const [previousQuestions, setPreviousQuestions] = useState([]);
  const [
    recommendedSupplementsProductsOptions,
    setRecommendedSupplementsProductsOptions,
  ] = useState([]);
  const [prodFilterForRecSupp, setProdFilterForRecSupp] = useState({
    admin: true,
    perPage: 20,
    page: 1,
  });
  const [questionModalLoading, setQuestionModalLoading] = useState(false);
  // specific Question
  const [showSelectQuestionModal, setShowSelectQuestionModal] = useState(false);
  useEffect(() => {
    // healthGoal API.
    api
      .getAllGoal({ status: true, admin: true })
      .then((res) => {
        let goalData = res?.data?.data?.docs;
        setHealthGoal(getLabelValuePair(goalData, 'title', '_id'));
      })
      .catch((err) => {
        console.log(err);
      });
    window.onbeforeunload = () => '';
    if (productId) {
      setLoading(true);
      api
        .getProductById(productId)
        .then((res) => {
          setLoading(false);
          assignValues(res.data?.data);
          if (res.data?.data?.meta_data?.data) {
            let metadata_arr = [];
            metadata_arr = Object.keys(res.data?.data?.meta_data?.data).map(
              (key) => {
                return {
                  name: { label: key, value: key },
                  content: res.data?.data?.meta_data?.data[key],
                };
              },
            );
            setMetaDataInitialValues({ metadata: metadata_arr });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
    // brand
    getBrandOptions();
    // keywords
    getKeywordOptions();
    // category
  }, []);

  const getCategoriesFromSubCategory = (data) => {
    let categories = data?.map((el) => {
      return {
        label: el?.category?.name,
        value: el?.category?._id,
      };
    });

    let filteredCategories = categories.reduce((prev, curr) => {
      if (
        !prev.some(
          (obj) => obj.label === curr.label && obj.value === curr.value,
        )
      ) {
        prev.push(curr);
      }
      return prev;
    }, []);

    const categoryIds = filteredCategories.reduce(
      (prev, curr) => (prev ? prev + ',' + curr.value : curr.value),
      '',
    );

    getProductSubCategory(categoryIds);

    return filteredCategories;
  };

  const assignValues = (data) => {
    if (data.has_variant && data.variant_list) {
      // because we don't send variantAttribute to BE
      data.variantAttributes = Object.keys(data.variant_list).reduce(
        (prev, el) => {
          prev.push(
            VARIANT_ATTRIBUTE_OPTIONS.find((elem) => elem.value === el),
          );
          return prev;
        },
        [],
      );

      // Because variant_list for FE is {
      //     strength : {
      //       priority : 1
      //       values: [{value: '25mg', label: '25mg'},{value: '50mg', label: '50mg'}]
      //   }
      // } and for BE it's {
      //     strength : {
      //       priority : 1
      //       values: ['25mg', '50mg']
      //   }
      // }
      Object.keys(data.variant_list).forEach((el) => {
        let options = data.variant_list[el].values.map((el) => {
          return {
            value: el,
            label: el,
          };
        });
        data.variant_list[el] = { ...data.variant_list[el], values: options };
      });
    }
    if (data.health_goal)
      data.health_goal = {
        label: data?.health_goal?.title,
        value: data?.health_goal?._id,
      };
    else {
      data.health_goal = null;
    }
    // delete health_goal_id from data
    delete data.health_goal_id;
    data.category =
      data.type === 'product'
        ? getCategoriesFromSubCategory(data?.sub_categories)
        : Object.keys(data?.sub_categories).length
        ? {
            label: data?.sub_categories[0]?.category?.name,
            value: data?.sub_categories[0]?.category?._id,
          }
        : null;
    Object.keys(data?.sub_categories).length && data.type === 'medication'
      ? getProductSubCategory(
          data?.sub_categories[0]?.category?._id,
          data?.type,
        )
      : null;
    data.sub_category =
      data.type === 'product'
        ? data?.sub_categories?.map((el) => {
            return {
              label: el?.name,
              value: el?._id,
              categoryId: el?.category?._id,
            };
          })
        : {
            label: data?.sub_categories[0]?.name,
            value: data?.sub_categories[0]?._id,
          };
    data.brand = data.brand
      ? { label: data.brand?.name, value: data?.brand?._id }
      : '';
    data.vat_rate = VAT_RATE_OPTIONS.find((el) => el.value === data.vat_rate);
    data.related_keyword = data?.related_keyword?.map((el) => {
      return { label: el.name, value: el._id };
    });
    data.ingredients = data.ingrediant?.map((el) => {
      return { label: el, value: el };
    });
    data.recommended_product = data.recommended_product?.map((el) => {
      return { label: el?.name, value: el?._id, product: cloneDeep(el) };
    });
    if (data.stock?.length) {
      //change the stock's attributes back to label-value pair
      const strengthsArray = [];
      data?.stock?.forEach((el) => {
        let strength = { ...el };
        // disabling subscription price inputs
        if (data.is_subscribable && el.subscription_price) {
          let subscription_price = el.subscription_price?.map((el) => {
            return {
              ...el,
              isDisabled: true,
            };
          });
          strength = {
            ...strength,
            subscription_price,
          };
        }
        if (el?.attributes) {
          const attributes = {};
          Object.keys(el.attributes).forEach((elem) => {
            attributes[elem] = {
              label: el.attributes[elem],
              value: el.attributes[elem],
            };
          });
          strengthsArray.push({ ...strength, attributes });
        } else {
          strengthsArray.push(strength);
        }
      });
      data.strengths = strengthsArray;
      //
      delete data.stock;
    }

    data.photos =
      data?.photos?.map((el) => {
        return {
          ...el,
          PrevUrl: el?.location,
          alreadyExists: true,
        };
      }) || [];
    data.preRegPhotos =
      data?.preRegPhotos?.map((el) => {
        return {
          ...el,
          PrevUrl: el?.location,
          alreadyExists: true,
        };
      }) || [];
    data.landingpage_image =
      data?.landingpage_image?.map((el) => {
        return {
          ...el,
          PrevUrl: el?.location,
        };
      }) || [];
    if (data.information_label)
      data.information_label = {
        ...data.information_label,
        name: data.information_label.originalname,
      };
    setProductData((prev) => {
      return { ...prev, ...data };
    });
  };

  const getBrandOptions = (params) => {
    let brandParams = { perPage: 20, page: 1, ...params };
    api
      .getAllBrands(brandParams)
      .then((res) => {
        let brandData = res?.data?.data?.docs;
        setBrandOptions(getLabelValuePair(brandData, 'name', '_id'));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAsyncBrands = async (params) => {
    try {
      let brandParams = { perPage: 20, page: 1, ...params };
      const response = await api.getBrandsOnSearch(brandParams);
      let brandData = response?.data?.data?.docs;
      return getLabelValuePair(brandData, 'name', '_id');
    } catch (err) {
      console.log(err);
    }
  };
  const getAsyncKeywords = async (params) => {
    try {
      let keywordParams = { perPage: 20, page: 1, ...params };
      const response = await api.getKeywordsOnSearch(keywordParams);
      let keywordData = response?.data?.data;
      return getLabelValuePair(keywordData, 'name', '_id');
    } catch (err) {
      console.log(err);
    }
  };

  const getKeywordOptions = (params) => {
    let keywordParams = { perPage: 20, page: 1, ...params };
    api
      .getAllKeyword(keywordParams)
      .then((res) => {
        let keywordData = res?.data?.data?.docs;
        setKeywordOptions(getLabelValuePair(keywordData, 'name', '_id'));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeForProductDetails = (
    name,
    value,
    strengths = [],
    thisIndex = 0,
  ) => {
    if (name === 'strengths') {
      if (value) {
        strengths.forEach((el, index) => {
          strengths[index].isPrimary = false;
        });
      }
      strengths[thisIndex].isPrimary = value;
      return strengths;
    } else {
      setProductData({ ...productData, [name]: value });
    }
  };

  const handleVatRateChanged = (value) => {
    setHasVatRateChanged(value);
  };

  const getLatestProducts = (params) => {
    setProdFilterForRecSupp(params);
    api
      .getProducts({ ...params, type: productType })
      .then((res) => {
        let productOptions = excludeCurrentProductFromList(
          productId,
          getProductOptions(res?.data?.data?.docs),
        );
        setRecommendedSupplementsProductsOptions(productOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAsyncOptions = (inputValue, fieldName) => {
    if (fieldName === 'brand') {
      return getAsyncBrands({ search: inputValue });
    } else if (fieldName === 'keywords') {
      return getAsyncKeywords({ search: inputValue });
    } else if (fieldName === 'products') {
      return getAsyncProducts({ name: inputValue });
    }
  };

  const getAsyncProducts = async (params) => {
    try {
      params = {
        ...params,
        ...prodFilterForRecSupp,
        recommended_product: true,
        type: productType,
      };
      const response = await api.getProducts(params);
      let productData = (response?.data?.data?.docs || []).filter(
        (p) => p.status,
      );
      return excludeCurrentProductFromList(
        productId,
        getProductOptions(productData),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const loadOptions = (inputValue, callback, fieldName) => {
    getAsyncOptions(inputValue, fieldName).then((results) => callback(results));
    return;
  };

  const debouncedLoadOptions = debounce(loadOptions, 500);

  const handleChangeForRecSupp = (name, value) => {
    if (name === 'recommended_product') {
      // max 5
      if (value.length > 5) {
        toastError('You can select only upto 5 products!!');
        return;
      }

      for (let i = 0; i < productData.recommended_product.length; i++) {
        let currentRecSupp = productData.recommended_product[i];
        if (currentRecSupp.value === value.value) {
          toastError('Product already added!');
          return;
        }
      }
      setProductData((prev) => {
        return { ...prev, [name]: value };
      });
      return;
    } else if (name === 'brandForRecSupp') {
      // api call to get prods of that brand
      if (value?.value !== 'all') {
        getLatestProducts({
          ...prodFilterForRecSupp,
          admin: true,
          brand: value?.value,
        });
      } else {
        let params = { ...prodFilterForRecSupp, admin: true };
        delete params.brand;
        getLatestProducts(params);
      }
    } else if (name === 'faq') {
      let faqClone = [...productData.faq];
      if (value?.index || value?.index === 0)
        faqClone[value?.index] = {
          question: value?.question,
          answer: value?.answer,
        };
      else faqClone.push(value);

      setProductData((prev) => {
        return { ...prev, faq: faqClone };
      });
      return;
    } else if (name === 'tick_points') {
      let tickPointsClone = [...productData.tick_points];
      if (value?.index || value?.index === 0)
        tickPointsClone[value?.index] = value?.value;
      else tickPointsClone.push(value?.value);
      setProductData((prev) => {
        return { ...prev, tick_points: tickPointsClone };
      });
      return;
    } else if (name === 'preRegFaq') {
      let preRegFaqClone = [...productData.preRegFaq];
      if (value?.index || value?.index === 0)
        preRegFaqClone[value?.index] = {
          question: value?.question,
          answer: value?.answer,
        };
      else preRegFaqClone.push(value);

      setProductData((prev) => {
        return { ...prev, preRegFaq: preRegFaqClone };
      });
      return;
    }
    setProductData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleChangeLPFaqTick = (name, value) => {
    if (name === 'landingpage_faq') {
      let faqClone = [...productData.landingpage_faq];
      if (value?.index || value?.index === 0)
        faqClone[value?.index] = {
          question: value?.question,
          answer: value?.answer,
        };
      else faqClone.push(value);

      setProductData((prev) => {
        return { ...prev, landingpage_faq: faqClone };
      });
      return;
    } else if (name === 'landingpage_tick_points') {
      let tickPointsClone = [...productData.landingpage_tick_points];
      if (value?.index || value?.index === 0)
        tickPointsClone[value?.index] = value?.value;
      else tickPointsClone.push(value?.value);
      setProductData((prev) => {
        return { ...prev, landingpage_tick_points: tickPointsClone };
      });
      return;
    }
  };

  const deleteRecSupp = (prodId) => {
    const { recommended_product } = productData && productData;
    let recommendedSupplementsClone = [...recommended_product];
    recommendedSupplementsClone = recommendedSupplementsClone.filter(
      (el) => el.value !== prodId,
    );
    setProductData((prev) => {
      return { ...prev, recommended_product: recommendedSupplementsClone };
    });
  };

  const deleteFAQAndTickPoints = (name, index) => {
    if (name === 'faq') {
      const { faq } = productData;
      let faqClone = [...faq];
      faqClone.splice(index, 1);
      setProductData((prev) => {
        return { ...prev, faq: faqClone };
      });
    } else if (name === 'tick_points') {
      const { tick_points } = productData;
      let tickPointsClone = [...tick_points];
      tickPointsClone.splice(index, 1);
      setProductData((prev) => {
        return { ...prev, tick_points: tickPointsClone };
      });
    } else if (name === 'preRegFaq') {
      const { preRegFaq } = productData;
      let preRegFaqClone = [...preRegFaq];
      preRegFaqClone.splice(index, 1);
      setProductData((prev) => {
        return { ...prev, preRegFaq: preRegFaqClone };
      });
    }
  };

  const deleteLPFAQAndTickPoints = (name, index) => {
    if (name === 'landingpage_faq') {
      const { landingpage_faq } = productData;
      let faqClone = [...landingpage_faq];
      faqClone.splice(index, 1);
      setProductData((prev) => {
        return { ...prev, landingpage_faq: faqClone };
      });
    } else if (name === 'landingpage_tick_points') {
      const { landingpage_tick_points } = productData;
      let tickPointsClone = [...landingpage_tick_points];
      tickPointsClone.splice(index, 1);
      setProductData((prev) => {
        return { ...prev, landingpage_tick_points: tickPointsClone };
      });
    }
  };

  const validationSchemaForProducts = Yup.object({
    name: Yup.string().required(getErrorMessage('required', 'Product name')),
    type: Yup.string().required(getErrorMessage('required', 'Product Type')),
    category: Yup.array()
      .min(1, getErrorMessage('select-required', 'Category'))
      .nullable(),
    sub_category: Yup.array()
      .min(1, getErrorMessage('select-required', 'Sub-category'))
      .nullable(),
    brand: Yup.object()
      .nullable()
      .required(getErrorMessage('select-required', 'brand')),
    vat_rate: Yup.object()
      .nullable()
      .required(getErrorMessage('select-required', 'Vat Rate')),
    sku: Yup.string().required(getErrorMessage('required', 'sku')),
    photos: Yup.array().min(1, getErrorMessage('required', 'Product Image')),
    strengths: Yup.array()
      .min(1, getErrorMessage('required', 'Min 1 strength & size required'))
      .of(
        Yup.object().shape({
          barcode: Yup.string().required(
            getErrorMessage('required', 'Barcode'),
          ),
          stock_level: Yup.string()
            .required(getErrorMessage('required', 'Stock'))
            .matches(/^[0-9]\d*$/, 'It is not in correct format'),
          trade_price: Yup.string()
            .required(getErrorMessage('required', 'Trade Price'))
            .matches(/^[0-9]\d*(\.\d+)?$/, 'It is not in correct format'),
          rrp_price: Yup.string()
            .required(getErrorMessage('required', 'RRP Price'))
            .matches(/^[0-9]\d*(\.\d+)?$/, 'It is not in correct format'),
        }),
      ),
    description: Yup.string().required(
      getErrorMessage(
        'required',
        productType === 'medication' ? 'Medicine Description' : 'Description',
      ),
    ),
  });

  const validationSchemaForMedication = Yup.object({
    name: Yup.string().required(getErrorMessage('required', 'Product name')),
    type: Yup.string().required(getErrorMessage('required', 'Product Type')),
    category: Yup.object()
      .required(getErrorMessage('select-required', 'Category'))
      .nullable(),
    sub_category: Yup.object()
      .required(getErrorMessage('select-required', 'Sub-category'))
      .nullable(),
    vat_rate: Yup.object()
      .nullable()
      .required(getErrorMessage('select-required', 'Vat Rate')),
    sku: Yup.string().required(getErrorMessage('required', 'sku')),
    photos: Yup.array().min(1, getErrorMessage('required', 'Product Image')),
    strengths: Yup.array()
      .min(1, getErrorMessage('required', 'Min 1 strength & size required'))
      .of(
        Yup.object().shape({
          barcode: Yup.string().required(
            getErrorMessage('required', 'Barcode'),
          ),
          stock_level: Yup.string()
            .required(getErrorMessage('required', 'Stock'))
            .matches(/^[0-9]\d*$/, 'It is not in correct format'),
          trade_price: Yup.string()
            .required(getErrorMessage('required', 'Trade Price'))
            .matches(/^[0-9]\d*(\.\d+)?$/, 'It is not in correct format'),
          rrp_price: Yup.string()
            .required(getErrorMessage('required', 'RRP Price'))
            .matches(/^[0-9]\d*(\.\d+)?$/, 'It is not in correct format'),
        }),
      ),
    description: Yup.string().required(
      getErrorMessage(
        'required',
        productType === 'medication' ? 'Medicine Description' : 'Description',
      ),
    ),
    // about: Yup.string().required(getErrorMessage('required', 'About')),
    // how_works: Yup.string().required(
    //   getErrorMessage('required', 'How does it works'),
    // ),
  });

  const validationSchemaForLandingPage = Yup.object({
    landingpage_title: Yup.string().required(
      getErrorMessage('required', 'Title'),
    ),
    landingpage_description: Yup.string().required(
      getErrorMessage('required', 'Description'),
    ),
    landingpage_image: Yup.array().min(1, getErrorMessage('required', 'Image')),
  });

  const onSubmitForPageOne = (values, final) => {
    // Check for the duplicate variants
    if (values.has_variant) {
      let noOfPrimaryVariant = 0;
      for (let index = 0; index < values.strengths.length; index++) {
        if (values.strengths[index].isPrimary)
          noOfPrimaryVariant = noOfPrimaryVariant + 1;
        let arrOfOtherRemainingStrengthsObj = [...values.strengths];
        arrOfOtherRemainingStrengthsObj.splice(index, 1);
        for (let ind = 0; ind < arrOfOtherRemainingStrengthsObj.length; ind++) {
          if (
            _.isEqual(
              values.strengths[index].attributes,
              arrOfOtherRemainingStrengthsObj[ind].attributes,
            )
          ) {
            toastError(
              `Duplicate Varaints Found at position ${index + 1} and ${
                values.strengths.indexOf(arrOfOtherRemainingStrengthsObj[ind]) +
                1
              }`,
            );
            return;
          }
        }
      }
      if (noOfPrimaryVariant == 0) {
        toastError('Please select the primary variant!!');
        return;
      }
      if (noOfPrimaryVariant > 1) {
        toastError('Please select only one primary variant!!');
        return;
      }
    }

    if (hasVatRateChanged) {
      let strengthClone = productData.strengths;
      strengthClone?.forEach((el, index) => {
        el.margin = calculateMargin(
          el.trade_price,
          el.rrp_price,
          productData.vat_rate,
        );
      });
    }
    setProductData((prev) => {
      return { ...prev, ...values };
    });
    handleNext();
  };

  const handleSubmit = (validateSubscriptionInfo, updatedValues = null) => {
    if (
      validateSubscriptionInfo &&
      validateSubscriptionInfo !== 'landing_page' && validateSubscriptionInfo !== 'pre_assessment_page'
    ) {
      // Subscription span wording validation
      let validationError = false;

      if (!productData?.subscription_span?.length) {
        toastError('Add Subscription Span');
        return;
      }
      productData?.subscription_span?.forEach((el) => {
        if (!el?.wording || el?.wording == '') {
          toastError(`Add wording for ${el?.label} subscription`);
          validationError = true;
        }
      });
      if (validationError) return;

      // follow up question validation
      if (!productData?.follow_up_questions?.length) {
        toastError('Please Add Follow Up Questions');
        return;
      }

      // email wording validation
      if (productData?.subscription_email_template === '') {
        toastError('Please Add Email Wording');
        return;
      }
    }
    setIsSubmitDisabled(true);
    let payload = cloneDeep(productData);
    payload.ingrediant = payload.ingredients;
    delete payload.ingredients;
    if (validateSubscriptionInfo === 'landing_page') {
      payload = {
        ...payload,
        ...updatedValues,
      };
    }
    if (validateSubscriptionInfo === 'pre_assessment_page') {
      payload = {
        ...payload,
        ...updatedValues,
      };
    }
    payload = getProductPayload(payload);
    
    if (payload.information_label?.location) {
      delete payload.information_label;
    }
    payload['metadata_id'] = payload?.meta_data?._id || '';

    delete payload.meta_data;
    delete payload.subscription_span;
    const formDataPayload = getFormDataFromObject(payload);
    
    formDataPayload.delete('photos');
    formDataPayload.delete('preRegPhotos');
    formDataPayload.delete('deletetable_variant');
    payload?.photos.forEach((el) => {
      formDataPayload.append('photos', el);
    });
    payload?.preRegPhotos.forEach((el) => {
      formDataPayload.append('preRegPhotos', el);
    });
    if (formDataPayload.get('isLandingpageImageUpdate') === 'true') {
      formDataPayload.delete('landingpage_image');
      payload?.landingpage_image.forEach((el) => {
        formDataPayload.append('landingpage_image', el);
      });
      formDataPayload.delete('isLandingpageImageUpdate');
    } else {
      formDataPayload.delete('isLandingpageImageUpdate');
      formDataPayload.delete('landingpage_image');
      formDataPayload.append(
        'landingpage_old_image',
        JSON.stringify(payload.landingpage_image),
      );
    }

    let metadataObj = {};
    metaDataInitialValues &&
      metaDataInitialValues.metadata &&
      metaDataInitialValues.metadata.length > 0 &&
      metaDataInitialValues.metadata.forEach((el) => {
        if (el?.name?.value) {
          metadataObj[el.name.value] = el.content;
        }
      });
    if (Object.keys(metadataObj).length > 0) {
      formDataPayload.append('metadata', JSON.stringify(metadataObj));
    }

    if (productId) {
      // edit
      api
        .putProduct(productId, formDataPayload, true)
        .then((res) => {
          if (
            payload.deletetable_variant &&
            payload.deletetable_variant.length
          ) {
            const variantApi = payload.deletetable_variant.map((id) =>
              api.deleteVariant(id),
            );
            Promise.all(variantApi).then((res) => {
              toastSuccess('Product Edited Successfully!!');
              setIsSubmitDisabled(false);
              navigate('/product');
            });
          } else {
            toastSuccess('Product Edited Successfully!!');
            setIsSubmitDisabled(false);
            navigate('/product');
          }
        })
        .catch((err) => {
          console.log(err);
          setIsSubmitDisabled(false);
          toastError(err?.message);
        });
      return;
    }

    api
      .postProduct(formDataPayload, true)
      .then((res) => {
        if (payload.deletetable_variant && payload.deletetable_variant.length) {
          const variantApi = payload.deletetable_variant.map((id) =>
            api.deleteVariant(id),
          );
          Promise.all(variantApi).then((res) => {
            toastSuccess('Product Edited Successfully!!');
            setIsSubmitDisabled(false);
            navigate('/product');
          });
        } else {
          toastSuccess('Product Added Successfully');
          setIsSubmitDisabled(false);
          navigate('/product');
        }
      })
      .catch((err) => {
        console.log(err);
        setIsSubmitDisabled(false);
        toastError(err?.message);
      });
  };

  const handlePrevious = () => {
    setCurrStep((prev) => prev - 1);
  };
  const handleNext = () => {
    setCurrStep((prev) => prev + 1);
  };
  const onDragEndImage = (result) => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const { photos } = productData;

      let prodImagesReordered = reorder(
        photos,
        source.index,
        destination.index,
      );

      prodImagesReordered = prodImagesReordered.map((el, index) => {
        return {
          ...el,
          order: index + 1,
        };
      });

      setProductData((prev) => {
        return { ...prev, photos: prodImagesReordered };
      });
    }
  };
  const calculateMargin = (tradePrice, RRRPrice, vatRate) => {
    if (tradePrice !== '' && RRRPrice !== '' && vatRate !== '') {
      const calculatedMargin =
        (RRRPrice / (1 + vatRate / 100) - tradePrice) /
        (RRRPrice / 1 + vatRate / 100);
      if (calculatedMargin) {
        setCalcMargin(parseFloat(calculatedMargin).toFixed(2));
      }
    }
  };

  const getProductSubCategory = (id, productType = null) => {
    api
      .getProductSubCategories({ categoryIds: id })
      .then((res) => {
        let subCategoryOptions = res?.data?.data?.map((el) => {
          return {
            label: `${el?.name} (${el?.category?.name})`,
            value: el?._id,
            categoryId: el?.category?._id,
          };
        });
        setSubCategoryOptions(subCategoryOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getQuestionAnswers = () => {
    setQuestionModalLoading(true);
    api
      .getQuestionAnswers()
      .then((res) => {
        let previousQuestionsClone = res?.data?.data;
        previousQuestionsClone = previousQuestionsClone.map((el) => {
          return { ...el, isDisabled: true, isChecked: false };
        });
        setQuestionModalLoading(false);
        setPreviousQuestions(previousQuestionsClone);
      })
      .catch((err) => {
        setQuestionModalLoading(false);
        console.log(err);
      });
  };

  // Subscription Section
  const handleChangeForSubInfo = (name, value, subSpan, thisIndex = false) => {
    if (name === 'strengths') {
      let strengthsClone = cloneDeep(productData.strengths);
      if (
        strengthsClone[thisIndex].subscription_price &&
        strengthsClone[thisIndex].subscription_price !== undefined
      ) {
        const thisSubscriptionIndex = strengthsClone[
          thisIndex
        ].subscription_price?.findIndex((el) => el.span === subSpan);

        if (thisSubscriptionIndex >= 0) {
          if (!value) {
            strengthsClone[thisIndex].subscription_price.splice(
              thisSubscriptionIndex,
              1,
            );
          } else {
            strengthsClone[thisIndex].subscription_price[
              thisSubscriptionIndex
            ].price = value;
          }
        } else {
          strengthsClone[thisIndex].subscription_price = [
            ...strengthsClone[thisIndex].subscription_price,
            { span: subSpan, price: value },
          ];
        }
      } else {
        strengthsClone[thisIndex].subscription_price = [
          { span: subSpan, price: value },
        ];
      }
      setProductData({ ...productData, strengths: strengthsClone });
    } else if (name === 'subscription_span') {
      if (thisIndex || thisIndex === 0) {
        //
        const subscription_span = [...productData.subscription_span];
        subscription_span[thisIndex] = value;
        setProductData({ ...productData, subscription_span });
      } else {
        setProductData({ ...productData, [name]: value });
      }
    } else {
      setProductData({ ...productData, [name]: value });
    }
  };

  const handleAddMainQuestion = () => {
    const { follow_up_questions } = productData;
    let followUpQuestionsClone = cloneDeep(follow_up_questions);
    followUpQuestionsClone.push(emptyQuestion);
    setProductData((prev) => {
      return { ...prev, follow_up_questions: followUpQuestionsClone };
    });
  };

  const handleAddSubQuestion = (index) => {
    const { follow_up_questions } = productData;
    let followUpQuestionsClone = cloneDeep(follow_up_questions);
    followUpQuestionsClone[index].subQuestions.push({
      question: '',
      answer: '',
      questionType: { label: 'Input', value: 'input' },
      selectedAnswer: null,
      isAnswerDisabled: true,
      isHTML: false,
    });
    setProductData((prev) => {
      return { ...prev, follow_up_questions: followUpQuestionsClone };
    });
  };

  const handleChangeForMainQuestion = (name, value, index) => {
    const { follow_up_questions } = productData;
    let followUpQuestionsClone = cloneDeep(follow_up_questions);
    followUpQuestionsClone[index][name] = value;
    if (name === 'questionType') {
      if (value.value === 'boolean' || value.value === 'select') {
        followUpQuestionsClone[index].isAddSubQuestionsDisabled = false;
        if (value.value === 'boolean') {
          followUpQuestionsClone[index].isAnswerDisabled = false;
        }
      } else {
        followUpQuestionsClone[index].isAnswerDisabled = true;
        followUpQuestionsClone[index].isAddSubQuestionsDisabled = true;
        followUpQuestionsClone[index].subQuestions = [];
        followUpQuestionsClone[index].preferredAnswer = null;
      }
    }
    setProductData((prev) => {
      return { ...prev, follow_up_questions: followUpQuestionsClone };
    });
  };

  const handleChangeForSubQuestion = (name, value, subIndex, index) => {
    const { follow_up_questions } = productData;
    let followUpQuestionsClone = cloneDeep(follow_up_questions);
    let subQuestionClone = cloneDeep(
      followUpQuestionsClone[index]?.subQuestions,
    );
    subQuestionClone[subIndex][name] = value;

    if (name === 'questionType' && value.value === 'boolean') {
      subQuestionClone[subIndex].isAnswerDisabled = false;
    } else if (name === 'questionType' && value.value !== 'boolean') {
      subQuestionClone[subIndex].isAnswerDisabled = true;
      subQuestionClone[subIndex].preferredAnswer = null;
    }
    followUpQuestionsClone[index].subQuestions = subQuestionClone;
    setProductData((prev) => {
      return { ...prev, follow_up_questions: followUpQuestionsClone };
    });
  };

  const handleDeleteMainQuestion = (index) => {
    const { follow_up_questions } = productData;
    let followUpQuestionsClone = cloneDeep(follow_up_questions);
    followUpQuestionsClone.splice(index, 1);
    setProductData((prev) => {
      return { ...prev, follow_up_questions: followUpQuestionsClone };
    });
  };

  const handleDeleteSubQuestion = (subIndex, index) => {
    const { follow_up_questions } = productData;
    let followUpQuestionsClone = cloneDeep(follow_up_questions);
    let subQuestionClone = cloneDeep(
      followUpQuestionsClone[index]?.subQuestions,
    );
    subQuestionClone.splice(subIndex, 1);
    followUpQuestionsClone[index].subQuestions = subQuestionClone;
    setProductData((prev) => {
      return { ...prev, follow_up_questions: followUpQuestionsClone };
    });
  };

  const onDragEndQuestion = (result) => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const { follow_up_questions } = productData;
      let questionsReordered = reorder(
        follow_up_questions,
        source.index,
        destination.index,
      );

      questionsReordered = questionsReordered.map((el, index) => {
        return {
          ...el,
          order: index + 1,
        };
      });
      setProductData((prev) => {
        return { ...prev, follow_up_questions: questionsReordered };
      });
    }
  };

  const handleChangeForOptions = (index, value) => {
    const { follow_up_questions } = productData;
    let followUpQuestionsClone = cloneDeep(follow_up_questions);
    if (index === false) {
      // change for is Single
      followUpQuestionsClone[selectedQuestionsIndex].isSingle = value;
    } else {
      followUpQuestionsClone[selectedQuestionsIndex].options[index] = value;
    }
    setProductData((prev) => {
      return { ...prev, follow_up_questions: followUpQuestionsClone };
    });
  };

  // select options modal methods
  const handleAddOption = () => {
    const { follow_up_questions } = productData;
    let followUpQuestionsClone = cloneDeep(follow_up_questions);
    followUpQuestionsClone[selectedQuestionsIndex]?.options
      ? followUpQuestionsClone[selectedQuestionsIndex].options.push('')
      : (followUpQuestionsClone[selectedQuestionsIndex].options = ['']);
    setProductData((prev) => {
      return { ...prev, follow_up_questions: followUpQuestionsClone };
    });
  };

  const handleDeleteForOptions = (index) => {
    const { follow_up_questions } = productData;
    let followUpQuestionsClone = cloneDeep(follow_up_questions);
    const options = followUpQuestionsClone[
      selectedQuestionsIndex
    ].options.filter((el, i) => i !== index);
    followUpQuestionsClone[selectedQuestionsIndex].options = options;
    setProductData((prev) => {
      return { ...prev, follow_up_questions: followUpQuestionsClone };
    });
  };

  const handleLandingPageSubmit = (values) => {
    const updatedValues = {
      landingpage_title: values.landingpage_title,
      shoparize_title: values.shoparize_title,
      shoparize_brand: values.shoparize_brand,
      shoparize_product_type: values.shoparize_product_type,
      shoparize_condition: values.shoparize_condition,
      shoparize_description: values.shoparize_description,
      shoparize_price: values.shoparize_price,
      shoparize_availability: values.shoparize_availability,
      shoparize_gtin: values.shoparize_gtin,
      shoparize_gender: values.shoparize_gender,
      shoparize_category: values.shoparize_category,
      shoparize_sales_price: values.shoparize_sales_price,
      landingpage_image: values.landingpage_image,
      landingpage_description: values.landingpage_description,
      landingpage_faq: productData.landingpage_faq,
      landingpage_tick_points: productData.landingpage_tick_points,
    };
    setProductData((prevValues) => ({
      ...prevValues,
      ...updatedValues,
    }));
    handleSubmit('landing_page', updatedValues);
  };

  const handlePreAssessmentSubmit = (values) => {
    const updatedValues = {
      preRegPhotos: values.preRegPhotos,
      preRegDescription: values.preRegDescription,
      preRegName: values.preRegName,
    };
    setProductData(prevValues => ({
      ...prevValues,
      ...updatedValues,
    }));
    handleSubmit('pre_assessment_page', updatedValues);
  };

  const handlePreAssmentNext = (values) => {
    const updatedValues = {
      preRegPhotos: values.preRegPhotos,
      preRegDescription: values.preRegDescription,
      preRegName: values.preRegName,
    };
    setProductData((prev) => {
      return { ...prev, ...updatedValues };
    });
    handleNext();
  }

  let steps = [
    <ProductDetails
      productData={productData}
      initialValues={productData}
      onSubmit={onSubmitForPageOne}
      validationSchema={
        productType === 'product'
          ? validationSchemaForProducts
          : validationSchemaForMedication
      }
      key={0}
      statusOptions={PRODUCT_STATUS_OPTIONS}
      categoryOptions={categoryOptions}
      subCategoryOptions={subCategoryOptions}
      vatRateOptions={VAT_RATE_OPTIONS}
      health_goalOptions={healthGoal}
      calculateMargin={calculateMargin}
      calcMargin={calcMargin}
      getProductSubCategory={getProductSubCategory}
      productType={productType}
      handlePrevious={handlePrevious}
      imgfile={productData.photos}
      show={show}
      showImage={showImage}
      selectData={selectData}
      debouncedLoadOptions={debouncedLoadOptions}
      brandOptions={brandOptions}
      handleChange={handleChangeForProductDetails}
      keywordOptions={keywordOptions}
      onDragEndImage={onDragEndImage}
      handleVatRateChanged={handleVatRateChanged}
      hasVatRateChanged={hasVatRateChanged}
      productId={productId}
    />,
    <RecommendedSupplements
      brandOptions={brandOptions}
      productOptions={recommendedSupplementsProductsOptions}
      getLatestProducts={getLatestProducts}
      debouncedLoadOptions={debouncedLoadOptions}
      productData={productData}
      handleChange={handleChangeForRecSupp}
      deleteRecSupp={deleteRecSupp}
      key={1}
      handlePrevious={handlePrevious}
      productType={productType}
      handleSubmit={
        productData.is_subscribable || productData.has_landingpage || productData.has_pre_assessment
          ? handleNext
          : handleSubmit
      }
      isSubmitDisabled={isSubmitDisabled}
      deleteFAQAndTickPoints={deleteFAQAndTickPoints}
    />,
  ];

  if (productData.has_pre_assessment) {
    steps.push(
      <PreAssessmentForm
        productData={productData}
        handleChange={handleChangeForRecSupp}
        productId={productId}
        productType={productType}
        handlePrevious={handlePrevious}
        isSubmitDisabled={isSubmitDisabled}
        handleSubmit={
          productData.is_subscribable || productData.has_landingpage ? handlePreAssmentNext : handlePreAssessmentSubmit
        }
        deleteFAQAndTickPoints={deleteFAQAndTickPoints}
      />,
    );
  }

  if (productData.is_subscribable) {
    steps.push(
      <SubscriptionDetails
        key={2}
        productData={productData}
        validationSchema={validationSchemaForMedication}
        // handleSubmit={() => handleSubmit(true)}
        handlePrevious={handlePrevious}
        handleChange={handleChangeForSubInfo}
        isSubmitDisabled={isSubmitDisabled}
        // questions related porps
        handleAddMainQuestion={handleAddMainQuestion}
        handleAddSubQuestion={handleAddSubQuestion}
        handleChangeForMainQuestion={handleChangeForMainQuestion}
        handleChangeForSubQuestion={handleChangeForSubQuestion}
        handleDeleteMainQuestion={handleDeleteMainQuestion}
        handleDeleteSubQuestion={handleDeleteSubQuestion}
        onDragEndQuestion={onDragEndQuestion}
        setSelectedQuestionsIndex={setSelectedQuestionsIndex}
        toggleShowOptionsModal={() => setShowOptionsModal((prev) => !prev)}
        handleSubmit={
          productData.has_landingpage ? handleNext : () => handleSubmit(true)
        }
      />,
    );
  }
  if (productData.has_landingpage) {
    steps.push(
      <LandingpageDetails
        key={!productData.is_subscribable ? 2 : 3}
        productData={productData}
        validationSchema={validationSchemaForLandingPage}
        productId={productId}
        handleSubmit={handleLandingPageSubmit}
        handlePrevious={handlePrevious}
        isSubmitDisabled={isSubmitDisabled}
        handleChange={handleChangeLPFaqTick}
        deleteFAQAndTickPoints={deleteLPFAQAndTickPoints}
      />,
    );
  }

  const handleMetaDataToggle = () => {
    setShowMetaModel(!showMetaModel);
  };

  const onSubmitOfMetaDataForm = (metaObj) => {
    let metadata_arr = [];
    metadata_arr = Object.keys(metaObj).map((key) => {
      return {
        name: { label: key, value: key },
        content: metaObj[key],
      };
    });
    setMetaDataInitialValues({ metadata: metadata_arr });
    setShowMetaModel(false);
  };

  useEffect(() => {
    api
      .getProductCategories({ showall: true })
      .then((res) => {
        let categoryOptions = getCategoryOptions(
          res?.data?.data?.docs,
          productType,
        );
        setCategoryOptions(categoryOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [productType]);

  return (
    <section className="content_wrapper product_add_main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <ol className="breadcrumb">
              <li>
                <Link to="/product" title="Product Management">
                  Product Management
                </Link>
              </li>
              <li>{productId ? 'Edit' : 'Add'} Product</li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="box_main">
              <div className="header_top">
                <h3>{productId ? 'Edit' : 'Add'} Product</h3>
                <Button
                  className="p-2 m-2 fw-bold"
                  onClick={() => setShowMetaModel(true)}
                >
                  {productId ? 'Edit' : 'Add'} Meta data
                </Button>
              </div>
            </div>
            <div className="middle_main_2">
              <div className="">
                <div className="step_custom">
                  <div className="wizard_inner m-auto">
                    <div className="connecting_line"></div>
                    <ul className="m-0 p-0 d-flex justify-content-between">
                      <li
                        className={
                          currStep === 0 ||
                          currStep === 1 ||
                          currStep === 2 ||
                          currStep === 3 ||
                          currStep === 4
                            ? 'active'
                            : null
                        }
                      >
                        <Link
                          to={
                            productId
                              ? `/addproduct?type=${productType}&id=${productId}`
                              : `/addproduct?type=${productType}`
                          }
                        >
                          <span>1</span>
                          <i>Product Details</i>{' '}
                        </Link>{' '}
                      </li>
                      <li
                        className={
                          currStep === 1 || currStep === 2 || currStep === 3 || currStep === 4
                            ? 'active'
                            : null
                        }
                      >
                        <Link
                          to={
                            productId
                              ? `/addproduct?type=${productType}&id=${productId}`
                              : `/addproduct?type=${productType}`
                          }
                        >
                          <span>2</span>
                          <i>Recommended Supplements</i>
                        </Link>
                      </li>
                      {productData?.has_pre_assessment ? (
                        <li
                          className={
                            productData.has_pre_assessment && (currStep === 2 || currStep === 3 || currStep === 4) 
                              ? 'active'
                              : null
                          }
                        >
                          <Link
                            to={
                              productId
                                ? `/addproduct?type=${productType}&id=${productId}`
                                : `/addproduct?type=${productType}`
                            }
                          >
                            <span>
                              3
                            </span>
                            <i>Parallel Product Page</i>
                          </Link>
                        </li>
                      ) : null}
                      {productData?.is_subscribable ? (
                        <li
                          className={
                            currStep === 2 || currStep === 3 ? 'active' : null
                          }
                        >
                          <Link
                            to={
                              productId
                                ? `/addproduct?type=${productType}&id=${productId}`
                                : `/addproduct?type=${productType}`
                            }
                          >
                            <span>{productData?.has_pre_assessment ? '4' : '3'}</span>
                            <i>Subscription Details</i>
                          </Link>
                        </li>
                      ) : null}
                      {productData?.has_landingpage ? (
                        <li
                          className={
                            productData.is_subscribable && currStep === 3
                              ? 'active'
                              : !productData.is_subscribable && currStep === 2
                              ? 'active'
                              : null
                          }
                        >
                          <Link
                            to={
                              productId
                                ? `/addproduct?type=${productType}&id=${productId}`
                                : `/addproduct?type=${productType}`
                            }
                          >
                            <span>
                              {productData?.has_pre_assessment ? productData?.is_subscribable ? '5' : '4' : productData?.is_subscribable ? '4' : '3'}
                            </span>
                            <i>Landing Page Details</i>
                          </Link>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
              </div>
              {loading ? <Loader /> : steps[currStep]}
            </div>
          </div>
        </div>
      </div>
      {showOptionsModal ? (
        <QuestionOptionModal
          handleChange={handleChangeForOptions}
          show={showOptionsModal}
          toggle={() => {
            if (showOptionsModal) {
              // modal close
              setShowOptionsModal(false);
              setSelectedQuestionsIndex(null);
            } else {
              // modal open
              setShowOptionsModal(true);
            }
          }}
          isSingle={
            productData.follow_up_questions[selectedQuestionsIndex]?.isSingle ||
            false
          }
          addOptions={handleAddOption}
          deleteOption={handleDeleteForOptions}
          options={
            productData.follow_up_questions[selectedQuestionsIndex]?.options
          }
        />
      ) : null}
      {showMetaModel && (
        <SEOModal
          handleAction={() => console.log('action')}
          handleToggle={handleMetaDataToggle}
          show={showMetaModel}
          initialValues={metaDataInitialValues}
          onSubmit={onSubmitOfMetaDataForm}
          fieldName="metadata"
          editId={productId}
        />
      )}
    </section>
  );
};

export default AddProduct;
