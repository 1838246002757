import React, { useEffect, useState } from 'react';
import SearchAndButtons from '../../common/UI/Search&Buttons';
import Loader from '../../common/UI/Loader';
import ReactTable from '../../common/UI/ReactTable';
import eyeIcon from '../../assets/images/eye_gray.svg';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const POMRegisterListing = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState('Table');
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [seeAll, setSeeAll] = useState(false);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [usersList, setUsersList] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [farmeciUsersData, setFarmeciUsersData] = useState([])
  const [mlpUsersData, setMlpUsersData] = useState([])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  
  const columns = [
    {
      Header: 'Name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            {`${row?.original?.first_name} ${row?.original?.last_name}`}
          </div>
        )
      }
    },
    {
      Header: 'Email',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            {row?.original?.email}
          </div>
        )
      }
    },
    {
      Header: 'User DOB',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
           {moment(row?.original?.dob).format('DD/MM/YYYY')}
          </div>
        );
      },
    },
    {
      Header: 'Action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        let site = ''
        if(row?.original?.isFarmeci)
        {
          site='farmeci'
        } else if(row?.original?.isMLP)
        {
          site='mlp'
        }
        else if(row?.original?.isMayfair)
        {
          site='mayfair'
        }
        else if(row?.original?.isLovelyMeds)
        {
          site='lovelymeds'
        }
        return (
          <div className="d-flex align-items-center">
            <span onClick={() => navigate(`/pom-register-orders/${row?.original?._id}?name=${`${row?.original?.first_name} ${row?.original?.last_name}&website=${site}&dob=${moment(row?.original?.dob).format('DD/MM/YYYY')}`}`)}>
              <img src={eyeIcon} alt="trash" width="24" height="24" />
            </span>
          </div>
        );
      },
    },
  ];

  // const fetchFarmeciUsersList = async () => {
  //   setLoading(true)
  //   let authToken = localStorage.getItem('authToken');
  //   let headers =  {'Access-Control-Allow-Origin': '*',
  //                   'Access-Control-Allow-Headers': '*',
  //                   'Content-Type': 'application/json',
  //                   Authorization: `Bearer ${authToken}` 
  //                 }
  //   axios.get('http://localhost:3001/' + `user/usersList?perPage=50&page=${currentPageNo}`, {
  //     headers: headers,
  //     params: {},
  //     responseType: 'json',
  //   })
  //   .then((response) => {
  //     setUsersList(response.data.data.docs)
  //     setTotalPage(response.data.data.totalPages)
  //     setLoading(false)
  //   })
  //   .catch((error) => {
  //     console.log(error)
  //     setLoading(false)
  //   }
  //   );
  // }

  const nextPageHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevPageHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const getOnSearch = (value, pageNo) => {
    setLoading(true)
    const params = { showall: true, search: value };
    
    let authToken = localStorage.getItem('authToken');
    let headers =  {'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}` 
                  }

    axios.get('https://api.farmeci.com/' + `user/usersList?perPage=500`, {
      headers: headers,
      params: params,
      responseType: 'json',
    })
    .then((resFarmeci) => {
      axios.get('https://api.mylondonpharmacy.co.uk/' + `user/usersList?perPage=500`, {
        headers: headers,
        params: params,
        responseType: 'json',
      })
      .then((resMLP) => {
        axios.get('https://api.mayfairclinic.ae/' + `user/usersList?perPage=500`, {
          headers: headers,
          params: params,
          responseType: 'json',
        })
        .then((resMayfair) => {
            axios.get('https://api.lovelymeds.com/' + `user/usersList?perPage=500`, {
              headers: headers,
              params: params,
              responseType: 'json',
            })
            .then((resLovelymeds) => {
              setUsersList(((resFarmeci.data.data.docs.concat(resMLP.data.data.docs)).concat(resMayfair.data.data.docs)).concat(resLovelymeds.data.data.docs).sort((a,b) => (a.first_name > b.first_name) ? 1 : ((b.first_name > a.first_name) ? -1 : 0)))
              setLoading(false)
            })
            .catch((error) => {
              console.log(error)
              setLoading(false)
            })
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
    })
    .catch((error) => {
      console.log(error)
      setLoading(false)
    }
    );
  };

  const getOrderByDate = (value, date_param) => {
    if (date_param == 'start') {
      setStartDate(value);
    } else if (date_param == 'end') {
      setEndDate(value);
    }

  };

  const fetchDateOrders = () => {
    setLoading(true)
    const params = { start_date: moment(startDate).format('YYYY-MM-DD'), end_date: moment(endDate).format('YYYY-MM-DD') };
    
    let authToken = localStorage.getItem('authToken');
    let headers =  {'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}` 
                  }
                  
    axios.get('https://api.farmeci.com/' + `user/usersList`, {
      headers: headers,
      params: params,
      responseType: 'json',
    })
    .then((resFarmeci) => {
      axios.get('https://api.mylondonpharmacy.co.uk/' + `user/usersList`, {
        headers: headers,
        params: params,
        responseType: 'json',
      })
      .then((resMLP) => {
        axios.get('https://api.mayfairclinic.ae/' + `user/usersList`, {
          headers: headers,
          params: params,
          responseType: 'json',
        })
        .then((resMayfair) => {
          axios.get('https://api.lovelymeds.com/' + `user/usersList`, {
            headers: headers,
            params: params,
            responseType: 'json',
          })
          .then((resLovelymeds) => {
            setUsersList(((resFarmeci.data.data.concat(resMLP.data.data)).concat(resMayfair.data.data)).concat(resLovelymeds.data.data).sort((a,b) => (a.first_name > b.first_name) ? 1 : ((b.first_name > a.first_name) ? -1 : 0)))
            setLoading(false)
          })
          .catch((error) => {
            console.log(error)
            setLoading(false)
          })
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
    })
    .catch((error) => {
      console.log(error)
      setLoading(false)
    }
    );
  }

  const onSearchDebounce = debounce(getOnSearch, 1000);

  const onUserSearchChange = (value) => {
    onSearchDebounce(value, currentPageNo);
  };

  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div className="header_top">
                  <h3>POM Register</h3>
                </div>
                <div className="middle_main">
                  <SearchAndButtons
                    onSearchChange={onUserSearchChange}
                    searchPlaceholder={'Search by Name, Email...'}
                    seeAllButton={false}
                    isDispatched={true}
                    getOrderByDate={getOrderByDate}
                    startDate={startDate}
                    endDate={endDate}
                    fetchDateOrders={fetchDateOrders}
                  />
                  {loading ? (
                    <Loader loading={loading} />
                  ) : (
                    <ReactTable
                      title="Blogs"
                      tableColumns={columns}
                      tableData={usersList}
                      seeAll={seeAll}
                      nextHandle={nextPageHandle}
                      prevHandle={prevPageHandle}
                      totalPage={totalPage}
                      pageNo={currentPageNo}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </>
  );
};

export default POMRegisterListing;
