import React, { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import Loader from '../common/UI/Loader';
import ReactTable from '../common/UI/ReactTable';
import SearchAndButtons from '../common/UI/Search&Buttons';
import OrderTable from '../components/Orders/orderTable';
import services from '../api/api';
import fridgeIcon from '../assets/images/fridge.svg';
import { isUserAdmin } from '../utils/helpers';
import { orderSearchbarPlaceholder } from '../common/constants';
const AssignedOrdersManagement = () => {
  const [data, setData] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [orderProductList, setOrderProductList] = useState([]);
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    setLoading(true);
    getOrders(currentPageNo);
    getOrderProductList()
  }, [currentPageNo, seeAll, searchVal]);

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const getOrders = (pageNo, value = null) => {
    let params = seeAll
      ? { showall: true }
      : {
          perPage: 10,
          page: pageNo,
        };

    params = { ...params, pharmacist_id: userId, status: 'placed' };

    if (value || (value === null && searchVal))
      params = {
        ...params,
        search: value === null && searchVal ? searchVal : value,
      };

    services
      .getAllOrders(params)
      .then((res) => {
        let data = res?.data?.data?.docs;
        setData(data);
        setTotalPage(res?.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const handleOrderSearch = (value) => {
    setSearchVal(value);
  };

  const onSearchDebounce = debounce(handleOrderSearch, 1000);

  const seeAllHandle = () => {
    setSeeAll((prev) => !prev);
  };

  const getOrderProductList = () => {
    services
    .getPendingOrderProductList()
    .then((res) => {
      console.log(res.data.data);
      
      let productOptions = res.data.data.map((product) => {return {label: product.productName, value: product._id}})
      setOrderProductList(productOptions)      
    })
    .catch((err) => {
      console.log('error', err);
    });
  }

  const handleFilterChange = (value) => {
        
    let params = { showall: true, status: 'placed', pharmacist_id: userId }
  
    if (searchVal)
      params = {
        ...params,
        search: searchVal,
        
      };
    
    params = {...params, product_id: value.value}
    
    services
      .getAllOrders(params)
      .then((res) => {
        let data = res?.data?.data?.docs;
        setData(data);
        setTotalPage(res?.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
    }

  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div
                  className="header_top d-flex"
                  style={{ justifyContent: 'space-between' }}
                >
                  <h3>Order Management</h3>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <span style={{ fontSize: '14px', color: 'blue' }}>
                      Order contains medicine
                    </span>
                    <span style={{ fontSize: '14px', color: 'black' }}>
                      <span className="ms-2">
                        <img src={fridgeIcon} width={20} height={20} />
                      </span>
                      Has Fridge Item
                    </span>
                  </div>
                </div>
                <div className="middle_main">
                  <SearchAndButtons
                    onSearchChange={onSearchDebounce}
                    searchPlaceholder={orderSearchbarPlaceholder}
                    buttonName={isUserAdmin() ? 'Export Orders' : null}
                    seeAllHandle={seeAllHandle}
                    seeAll={seeAll}
                    loading={loading}
                    isPendingOrders={true}
                    filterOptions={orderProductList}
                    handleFilterChange={handleFilterChange}
                  />
                  <OrderTable
                    loading={loading}
                    prevHandle={prevHandle}
                    nextHandle={nextHandle}
                    data={data}
                    seeAll={seeAll}
                    totalPage={totalPage}
                    currentPageNo={currentPageNo}
                    tableFor={'Assigned'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignedOrdersManagement;
