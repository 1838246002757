import React, { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import Loader from '../common/UI/Loader';
import ReactTable from '../common/UI/ReactTable';
import SearchAndButtons from '../common/UI/Search&Buttons';
import OrderTable from '../components/Orders/orderTable';
import services from '../api/api';
import { isUserAdmin, isUserDoctor, isUserPharmacist } from '../utils/helpers';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { orderSearchbarPlaceholder } from '../common/constants';

const CSVheaders = [
  { label: 'Order Number', key: 'order_number' },
  { label: 'Customer Name', key: 'customer_name' },
  { label: 'Product/Medication Name', key: 'medication_name' },
  { label: 'Total Price', key: 'total_price' },
  { label: 'Total Items', key: 'total_items' },
  { label: 'Email Address', key: 'email' },
  { label: 'Delivery Service', key: 'delivery_service' },
  { label: 'Date', key: 'date' },
  { label: 'Assigned Clinician', key: 'doctor_name' },
  { label: 'Assigned Pharmacist', key: 'pharmacist_name' },
  { label: 'Status', key: 'status' },
  { label: 'Variant', key: 'variant' },
];

const DispatchedOrdersManagement = () => {
  const [data, setData] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [searchVal, setSearchVal] = useState('');
  const userId = localStorage.getItem('userId');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    // setLoading(true);
    getOrders(currentPageNo);
  }, [currentPageNo, seeAll, searchVal]);

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const getOrders = (pageNo, value = null) => {
    setLoading(true);
    let params = seeAll
      ? { showall: true, status: 'dispatched' }
      : {
          perPage: 10,
          page: pageNo,
          status: 'dispatched',
        };

    if (isUserAdmin()) params = { ...params };
    if (isUserDoctor()) params = { ...params, has_medicine: true };
    if (isUserPharmacist()) params = { ...params, pharmacist_id: userId };

    if (searchVal)
      params = {
        ...params,
        search: searchVal,
      };

    if (startDate && endDate) {
      params = {
        ...params,
        start_date: `${startDate.getFullYear()}-${
          startDate.getMonth() + 1
        }-${startDate.getDate()}`,
        end_date: `${endDate.getFullYear()}-${
          endDate.getMonth() + 1
        }-${endDate.getDate()}`,
      };
    }
    services
      .getAllOrders(params)
      .then((res) => {
        let data = res?.data?.data?.docs;
        setData(data);
        setTotalPage(res?.data?.data?.totalPages);
        setLoading(false);
        loadCSVdata(res.data?.data?.docs);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const handleOrderSearch = (value) => {
    setSearchVal(value);
  };

  const onSearchDebounce = debounce(handleOrderSearch, 1000);

  const seeAllHandle = () => {
    setSeeAll((prev) => !prev);
  };

  const loadCSVdata = (orderdata) => {
    const modifyorderdata = orderdata?.length
      ? orderdata?.map((el) => {
          return {
            order_number: el?.order_number,
            customer_name: `${el?.user?.first_name} ${el?.user?.last_name}`,
            medication_name: el?.orderItem?.reduce(
              (prev, curr, index) =>
                curr?.product_name
                  ? prev +
                    `${
                      index == el?.orderItem.length - 1
                        ? `${curr?.product_name}`
                        : `${curr?.product_name},`
                    }`
                  : prev,
              '',
            ),
            email: el?.user?.email,
            total_price: el?.total,
            total_items: el?.total_items,
            status: el?.current_order_status,
            delivery_service: el?.delivery?.title,
            doctor_name: `${el?.doctor?.first_name || ''} ${
              el?.doctor?.last_name || ''
            }`,
            pharmacist_name: `${el?.pharmacist?.first_name || ''} ${
              el?.pharmacist?.last_name || ''
            }`,
            date: `${moment(el?.createdAt).format('DD/MM/yyyy hh:mm A')}`,
            variant: JSON.stringify(el?.variants_name).slice(1, -1),
          };
        })
      : [];

    setCsvData(modifyorderdata);
  };

  const getOrderByDate = (value, date_param) => {
    if (date_param == 'start') {
      setStartDate(value);
    } else if (date_param == 'end') {
      setEndDate(value);
    }

    if (startDate && endDate) {
      let start_date = `${startDate.getFullYear()}-${startDate.getMonth()}-${startDate.getDate()}`;
      let end_date = `${endDate.getFullYear()}-${endDate.getMonth()}-${endDate.getDate()}`;
      let params = {
        showall: true,
        status: 'dispatched',
        start_date: start_date,
        end_date: end_date,
      };
    }
  };

  const fetchDateOrders = () => {
    getOrders(currentPageNo);
  };

  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div
                  className="header_top d-flex"
                  style={{ justifyContent: 'space-between' }}
                >
                  <h3>Order Management</h3>
                  {isUserAdmin() || isUserPharmacist() ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <span style={{ fontSize: '14px', color: 'blue' }}>
                        Order contains medicine
                      </span>
                    </div>
                  ) : null}
                </div>
                <div className="middle_main">
                  <SearchAndButtons
                    onSearchChange={onSearchDebounce}
                    searchPlaceholder={orderSearchbarPlaceholder}
                    buttonName={
                      isUserAdmin() ? (
                        <CSVLink
                          data={csvData}
                          filename={`orders_${moment().format(
                            'YYYY_MM_DD_HH_mm_ss',
                          )}.xlsx`}
                          target="_blank"
                          headers={CSVheaders}
                          style={{ color: '#fff' }}
                        >
                          Export Orders
                        </CSVLink>
                      ) : null
                    }
                    seeAllHandle={seeAllHandle}
                    loading={loading}
                    startDate={startDate}
                    endDate={endDate}
                    getOrderByDate={getOrderByDate}
                    isDispatched={true}
                    fetchDateOrders={fetchDateOrders}
                  />
                  <OrderTable
                    loading={loading}
                    prevHandle={prevHandle}
                    nextHandle={nextHandle}
                    data={data}
                    seeAll={seeAll}
                    totalPage={totalPage}
                    currentPageNo={currentPageNo}
                    tableFor={'Dispatched'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DispatchedOrdersManagement;
